import { Link, graphql } from "gatsby"
import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SplitTile from "../../components/splitTile"

/** @jsx jsx */
import {
  jsx,
  Button,
  Heading,
  Text,
  Box,
  Styled,
  Link as ThemeUILink,
} from "theme-ui"

import * as content from "../../content/chatgpt/content.js"
import marketingCover from "../../images/books/cover_uschtrin_chatgpt.jpg"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      image={marketingCover}
      location={location}
      title="Schreiben mit ChatGPT für Autorinnen und Autoren - Sandra Uschtrin"
      description="Schreiben mit ChatGPT für Autorinnen und Autoren. Von der Ideenfindung bis zur Vermarktung"
    />
    <SplitTile
      image={data.cover.childImageSharp.fluid}
      imageInfo="Cover von »Schreiben mit ChatGPT für Autorinnen und Autoren«"
    >
      <Heading as="h1" sx={{ fontSize: 4, fontWeight: 900 }}>
        Schreiben mit ChatGPT für Autorinnen und Autoren
      </Heading>
      <Text>Von der Ideenfindung bis zur Vermarktung</Text>
      <p sx={{ fontSize: 4, fontWeight: "bold", marginBottom: `0.5rem` }}>
        6,99 EUR E-Book
      </p>
      <ThemeUILink
        as={Link}
        variant="default"
        to="https://www.reinlesen.de/9783967461015/schreiben-mit-chatgpt-fuer-autorinnen-und-autoren/"
      >
        <Button>im Buchhandel bestellen</Button>
      </ThemeUILink>
      <p
        sx={{
          fontSize: 4,
          fontWeight: "bold",
          marginBottom: `0.5rem`,
          marginTop: `2rem`,
        }}
      >
        16,90 EUR Print
      </p>
      <ThemeUILink
        as={Link}
        variant="default"
        to="https://shop.autorenwelt.de/products/schreiben-mit-chatgpt-fuer-autorinnen-und-autoren"
      >
        <Button>im Autorenwelt-Shop bestellen</Button>
      </ThemeUILink>
    </SplitTile>
    <Box>
      <Text variant="subheading">Bibliografische Angaben</Text>
      <Text variant="default">Autorin: Sandra Uschtrin</Text>
      <Text variant="default">ISBN: 9783967461008</Text>
      <Text variant="default">
        Titel: Schreiben mit ChatGPT für Autorinnen und Autoren
        <br />
        Von der Ideenfindung bis zur Vermarktung
      </Text>
      <Text variant="default">
        1. Auflage
        <br />
        Mai 2023
      </Text>

      <Text variant="subheading">Klappentext</Text>

      <Text variant="default">
        Entdecken Sie, wie ChatGPT Ihnen beim Schreiben und Vermarkten Ihrer
        Bücher helfen kann und wie Sie das Beste aus der KI herausholen. Kann
        ChatGPT auch Romane und spannende Unterhaltung schreiben? Kann es
        glaubhafte Figuren entwickeln und Dialoge entwerfen, die die Handlung
        vorantreiben? Was sind die Stärken und Schwächen dieser Technologie?
        Sandra Uschtrin hat ChatGPT gründlich getestet und zeigt Ihnen in diesem
        Ratgeber, wie Sie die künstliche Intelligenz als Sparringspartnerin und
        für Marketing-Aktionen nutzen können. Ein Muss für alle Autorinnen und
        Autoren, die mehr über die schöne neue Welt des Bücherschreibens wissen
        wollen!
      </Text>

      <Text variant="subheading">Rezensionen</Text>
      {content.reviewLinks.map((link, index) => (
        <Text variant="default">
          {index + 1}.{" "}
          <Styled.a
            href={link.target}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </Styled.a>{" "}
          <FaExternalLinkAlt sx={{ fontSize: 14 }} />
        </Text>
      ))}

      <Text variant="subheading">Zum Inhalt</Text>

      <Text variant="default">1 „ChatGPT, schreibe mir bitte ein Buch!“</Text>

      <Text variant="default">
        2 Grundlegendes zum Thema künstliche Intelligenz (KI)
        <br />
        2.1 Mögliche Gefahren bei mehr und mehr KI-Büchern
        <br />
        2.2 Haken Sie nach! Glauben Sie nicht alles! Bleiben Sie wachsam!
        <br />
        2.3 Auswirkungen von KI auf Schriftsteller:innen und Übersetzer:innen
        <br />
        2.4 In eigener Sache
      </Text>

      <Text variant="default">
        3 Thema erschließen – Mindmap erstellen
        <br />
        3.1 Projekt A: dieser Ratgeber
        <br />
        3.2 Projekt B: Liebesroman
        <br />
        3.3 Mein Fazit
      </Text>

      <Text variant="default">
        4 Zwischendurch: Was sind eigentlich Prompts?
      </Text>

      <Text variant="default">
        5 Figuren entwickeln
        <br />
        5.1 Warum Amelie nicht Brunhilde heißt
        <br />
        5.2 Namen für die Protagonisten erfinden
        <br />
        5.3 Ravins dunkles Geheimnis
        <br />
        5.4 Weitere Personen
        <br />
        5.5 Mein Fazit
      </Text>

      <Text variant="default">
        6 Erzählperspektive
        <br />
        6.1 Welche Erzählperspektive schlägt die KI vor?
        <br />
        6.2 Die Erzählperspektive ändern
        <br />
        6.3 Mein Fazit
      </Text>

      <Text variant="default">
        7 Ort der Handlung
        <br />
        7.1 Der Blick aus Amelies Hotelzimmer
        <br />
        7.2 Ein Ausflug nach Port Louis
        <br />
        7.3 Mein Fazit
      </Text>

      <Text variant="default">
        8 Stil
        <br />
        8.1 Einfach und kompliziert – Kinderbuchautor:innen aufgepasst!
        <br />
        8.2 Dialekt, Österreichisch, Schweizerisch und Fremdsprachen
        <br />
        8.3 Mein Fazit
      </Text>

      <Text variant="default">
        9 Dialoge schreiben
        <br />
        9.1 Amelie und Ravin streiten sich
        <br />
        9.2 Amelie heult sich bei Laura aus
        <br />
        9.3 Innere Monologe einer Katze – Referenzlisten und Halluzinationen
        <br />
        9.4 Wenn Amelie wie Donald Trump sprechen soll
        <br />
        9.5 Mein Fazit
      </Text>

      <Text variant="default">
        10 Plotten und Erzählstränge
        <br />
        10.1 Liebesroman auf Mauritius – der Plot in drei und fünf Akten
        <br />
        10.2 Schneeflocken-Methode nach Randy Ingermanson
        <br />
        10.3 Zwei, drei, vier, fünf, sechs Erzählstränge ...
        <br />
        10.4 Lokalkolorit – ChatGPT greift in den Farbtopf
        <br />
        10.5 Mein Fazit
      </Text>

      <Text variant="default">
        11 Vom Anfang und Ende
        <br />
        11.1 Der Romananfang: Amelie kommt auf Mauritius an
        <br />
        11.2 Das Romanende: Der Tanzabend im Hotel
        <br />
        11.3 Mein Fazit
      </Text>

      <Text variant="default">
        12 Exposé und (Arbeits-)Titel
        <br />
        12.1 Exposé (Liebesroman)
        <br />
        12.2 Titel (Liebesroman)
        <br />
        12.3 Titel (Ratgeber)
        <br />
        12.4 Mein Fazit
      </Text>

      <Text variant="default">
        13 Klappentexte, Keywords und das Überarbeiten von Texten
        <br />
        13.1 Klappentext (Liebesroman)
        <br />
        13.2 Klappentext (Ratgeber)
        <br />
        13.3 Überarbeiten von Texten
        <br />
        13.4 Mein Fazit
      </Text>

      <Text variant="default">
        14 Cover, Ausmalbücher und Illustrationen
        <br />
        14.1 Welches Cover für diesen Ratgeber?
        <br />
        14.2 Ausmalbücher, Illustrationen
      </Text>

      <Text variant="default">
        15 Marketingideen entwickeln
        <br />
        15.1 Marketingideen für den Liebesroman
        <br />
        15.2 Rezepte für Instagram-Posts kreieren lassen
        <br />
        15.3 Marketingideen für diesen Ratgeber
        <br />
        15.4 Mein Fazit
      </Text>

      <Text variant="default">
        16 Marketingtexte: Social-Media-Beiträge, Blogposts, Newslettertexte,
        E-Mails, Pressemitteilungen ...
        <br />
        16.1 Instagram-Post
        <br />
        16.2 E-Mail an einen Journalisten
        <br />
        16.3 Newslettertext
        <br />
        16.4 Mein Fazit
      </Text>

      <Text variant="default">
        17 Übersetzungen, IPA und Braille
        <br />
        17.1 DeepL-Übersetzer
        <br />
        17.2 Übersetzungen mit ChatGPT
        <br />
        17.3 Ein Buch in verschiedene Sprachen übersetzen lassen
        <br />
        17.4 Zweisprachige Ausgaben, IPA und Braille
        <br />
        17.5 Mein Fazit
      </Text>

      <Text variant="default">
        18 Fazit und Ausblick
        <br />
        18.1 Fazit
        <br />
        18.2 Ausblick
      </Text>

      <Text variant="default">
        19 Anhang
        <br />
        19.1 Belletristik-Liste
        <br />
        19.2 Rainer Maria Rilke: Briefe an einen jungen Dichter
        <br />
        19.3 Links
      </Text>

      <Text variant="default">20 Danksagung</Text>

      <Text variant="default">21 Über die Autorin</Text>

      <Text variant="subheading">Willkommen zu diesem Ratgeber!</Text>

      <Text variant="default">
        Als ich zum ersten Mal hörte, dass Google Angst vor einem Chatbot namens
        ChatGPT hat, wurde ich neugierig. Wovor zittert dieser mächtige
        Konzern? Schnell wurde mir klar: vor einem ernst zu nehmenden
        Konkurrenten. Denn wer eine Frage hat, bekommt bei ChatGPT eine Antwort
        und nicht – wie bei Google – zigtausend verschiedene Suchergebnisse.<br/>
        Als Verlegerin von zwei Fachzeitschriften für Autorinnen und Autoren habe
        ich mich natürlich sofort gefragt: Braucht es in naher Zukunft dann
        überhaupt noch Zeitschriften? Wenn sich jedes Kind per ChatGPT erklären
        lassen kann, was zum Beispiel ein Klappentext ist und worauf man beim
        Schreiben eines solchen Textes besonders achten sollte – gibt es dann
        noch einen Bedarf an einem solchen Artikel, der in einer Fachzeitschrift
        steht, für die man Geld bezahlt?<br/>
        Oder bedeutet ChatGPT das Ende von Ratgebern, Sach- und Fachbüchern … und
        den Beginn einer neuen Ära?<br/>
        Welche positiven und negativen Entwicklungen bringt diese künstliche
        Intelligenz (KI) für die Buchbranche mit sich? Und vor allem für die
        Autorinnen und Autoren, also diejenigen, die bisher für alle Texte
        verantwortlich waren und sich mit dem Schreiben ihren Lebensunterhalt
        oder doch zumindest ein Zubrot verdient haben?<br/>
        Kann man mit ChatGPT Artikel und Bücher schreiben? Auch Romane, Krimis? Ist das
        jetzt schon möglich? Und wenn ja, in welcher Qualität?<br/>
        Wofür eignet sich ChatGPT besonders gut? Worin ist es noch schlecht?<br/>
        Diesen Fragen wollte ich auf den Grund gehen.<br/>
        Also richtete ich mir einen Account ein und legte los.<br/>
        Das Ergebnis meiner Recherchen halten Sie in den Händen.
      </Text>
      <Text variant="default">
        Um ChatGPT nicht im luftleeren Raum zu testen, habe ich zwei „Versuchsreihen“
        entworfen: Zum einen sollte mich die KI beim Schreiben dieses Ratgebers
        unterstützen, zum anderen entwickelte ich mit ihr zusammen einen
        Liebesroman. Große Teile dieses Ratgebers stammen daher von ChatGPT.<br/>
        Damit Sie immer wissen, welche Passagen das sind, habe ich unsere Chats
        in Kursivschrift wiedergegeben.
      </Text>
      <Text variant="default">  
        Begonnen habe ich dieses Buch Ende Februar 2023 mit der kostenpflichtigen 
        Version ChatGPT Plus und dem „Model: Default (GPT-3.5)“. Mitte März 2023 gab
        es ein Update auf GPT-4. Wahrscheinlich wird es alle paar Monate Updates geben,
        die mehr können. ChatGPT wird sich rasant entwickeln. Aber noch steckt dieses 
        Tool in den Kinderschuhen.<br/>
        Das bietet viele Chancen. Zum Beispiel die Chance, gemeinsam mit anderen 
        Autorinnen und Autoren die Rahmenbedingungen für den Einsatz von KI aktiv 
        mitzugestalten. Mitreden kann aber nur, wer weiß, was heute schon möglich ist, 
        und ahnt, was bald möglich sein wird.
      </Text>
      <Text variant="default">
        ChatGPT kann Autorinnen und Autoren schon jetzt bei vielen Dingen
        unterstützen. Welche das sind, erfahren Sie auf den folgenden Seiten.
      </Text>
      <Text variant="default">
        Ich wünsche Ihnen viel Freude beim Lesen und Ausprobieren von ChatGPT!
      </Text>
      <Text variant="default">
        Sandra Uschtrin
      </Text>
      <Text variant="default">
        PS: Wenn Sie auf (neue) Anwendungen stoßen, die hier
        fehlen und von denen Sie denken, dass sie für andere Autorinnen und
        Autoren wichtig sein könnten, würde ich mich sehr freuen, wenn Sie mich
        darüber informieren. So kann dieser Ratgeber von Ausgabe zu Ausgabe
        besser werden und bleibt aktuell.<br/>
        Sie erreichen mich über meine Website
        https://www.schreiben-mit-chatgpt.de oder über info@uschtrin.de.
      </Text>
    </Box>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Book",
        "@id": "https://www.uschtrin.de/buecher/uschtrin-schreiben-mit-chatgpt",
        "url": "https://www.uschtrin.de/buecher/uschtrin-schreiben-mit-chatgpt",
        "name": "Schreiben mit ChatGPT für Autorinnen und Autoren - Von der Ideenfindung bis zur Vermarktung",
        "bookEdition": "1. Auflage",
        "publisher": {
          "@type": "Organization",
          "@id": "https://www.uschtrin.de#organisation",
          "name": "Uschtrin Verlag"
        },
        "editor": {
          "@type": "Person",
          "givenName": "Sandra",
          "familyName":"Uschtrin"
        },
        "image": "https://www.uschtrin.de${marketingCover}"
      }`}
    </script>
  </Layout>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "books/cover_uschtrin_chatgpt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
